import React, { useEffect } from 'react'
import { navigate } from "gatsby"

export default function PrivacyPolicy() {
    useEffect(() => {
        navigate('https://www.iubenda.com/privacy-policy/45157538')
    }, []);

    return null
}
